// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appProvincias/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Provincias`)
  return response.data.allItems
})

export const getData = createAsyncThunk('appProvincias/getData', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Provincias`, {params: param})
  return {
    param,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getProvincia = createAsyncThunk('appProvincias/getProvincia', async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/provincias/${id}`)
  return response.data.data
})

export const addProvincia = createAsyncThunk('appProvincias/addProvincia', async (Provincia, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Provincias/crear`, Provincia)
  await dispatch(getData(getState().provincias.params))
  await dispatch(getAllData())
  return response.data
})

export const deleteProvincia = createAsyncThunk('appProvincias/deleteProvincia', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/Provincias/${id}`)
  await dispatch(getData(getState().provincias.params))
  await dispatch(getAllData())
  return response.data
})

export const saveProvincia = createAsyncThunk('appProvincias/saveProvincia', async (Provincia, { dispatch, getState }) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Provincias`, Provincia)
  await dispatch(getData(getState().provincias.params))
  await dispatch(getAllData())
  return response.data
})

export const getRegiones = createAsyncThunk('appProvincias/getRegiones', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Regiones`)
  return response.data.allItems
})

export const downloadExcel = createAsyncThunk('appProvincias/downloadExcel', async params => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/provincias/excel`, params)
  return response
})

export const appProvinciasSlice = createSlice({
  name: 'appProvincias',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    regiones: [],
    selectedProvincia: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getProvincia.fulfilled, (state, action) => {
        state.selectedProvincia = action.payload
      })
      .addCase(getRegiones.fulfilled, (state, action) => {
        state.regiones = action.payload
      })
  }
})

export default appProvinciasSlice.reducer
