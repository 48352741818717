// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appInformes/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Informes`)
  return response.data.allItems
})

export const getData = createAsyncThunk('appInformes/getData', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Informes`, {params:param})
  return {
    param,
    data: response.data.data,
    allData: response.data.allItems,
    totalPages: response.data.total
  }
})

export const addInforme = createAsyncThunk('appInformes/addInforme', async (Informe, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Informes/crear`, Informe)
  await dispatch(getData(getState().informes.params))
  await dispatch(getAllData())
  return response.data
})

export const saveInforme = createAsyncThunk('appInformes/saveInforme', async (Informe) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Informes`, Informe)
  return response.data
})

export const approveInforme = createAsyncThunk('appInformes/approveInforme', async (Informe) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Informes/aprobar`, Informe)
  return response.data
})

export const rejectInforme = createAsyncThunk('appInformes/rejectInforme', async (Informe) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Informes/rechazar`, Informe)
  return response.data
})

export const downloadExcel = createAsyncThunk('appInformes/downloadExcel', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Informes/excel`, { params:param })
  return response
})

export const getRegiones = createAsyncThunk('appInformes/getRegiones', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Regiones`, {params:param})
  return response.data.allItems
})

export const getInformeById = createAsyncThunk('appInformes/getInformeById', async informeId => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Informes/${informeId}`)
  return response.data.data
})

export const getBitacoraInforme = createAsyncThunk('appInformes/getBitacoraInforme', async informeId => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Informes/Bitacora/${informeId}`)
  return response.data.allItems
})

export const getDocumentosInforme = createAsyncThunk('appInformes/getDocumentosInforme', async informeId => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Informes/Documentos/${informeId}`)
  return response.data.allItems
})

export const getComunasEnergeticas = createAsyncThunk('appInformes/getComunasEnergeticas', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ComunasEnergeticas`, {params:param})
  return response.data.allItems
})

export const appInformesSlice = createSlice({
  name: 'appInformes',
  initialState: {
    informeSelected: {},
    data: [],
    total: 1,
    params: {},
    allData: [],
    //totales: {totalProcesos: 0, totalDocente: 0, totalRenta: 0, totalOperaciones: 0},
    //pendientes: [],
    //regiones: [],
    //comunas: [],
    comunasEnergeticas: [],
    bitacora: [],
    documentos: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload.totalPages
      state.params = action.payload.param
      state.allData = action.payload.allData
    })
    .addCase(getAllData.fulfilled, (state, action) => {
      state.allData = action.payload
    })
    .addCase(getInformeById.fulfilled, (state, action) => {
      state.informeSelected = action.payload
    })
    .addCase(getComunasEnergeticas.fulfilled, (state, action) => {
      state.comunasEnergeticas = action.payload
    })
    .addCase(getDocumentosInforme.fulfilled, (state, action) => {
      state.documentos = action.payload
    })
    .addCase(getBitacoraInforme.fulfilled, (state, action) => {
      state.bitacora = action.payload
    })
  }
})


export default appInformesSlice.reducer
