// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appUsuarios/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Usuarios`)
  return response.data.allItems
})

export const getData = createAsyncThunk('appUsuarios/getData', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Usuarios`, {params: param})
  return {
    param,
    data: response.data.data,
    allData: response.data.allItems,
    totalPages: response.data.total
  }
})

export const getRegiones = createAsyncThunk('appUsuarios/getRegiones', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Regiones`)
  return response.data.allItems
})

export const getRoles = createAsyncThunk('appUsuarios/getRoles', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/UsuariosPerfiles`)
  return response.data.allItems
})

export const getUser = createAsyncThunk('appUsuarios/getUser', async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Usuarios/${id}`)
  return response.data.data
})


export const saveUser = createAsyncThunk('appUsuarios/saveUser', async (user, { dispatch, getState }) => {
  await axios.put(`${process.env.REACT_APP_API_URL}/api/Usuarios`, user)
  await dispatch(getData(getState().usuarios.params))
  await dispatch(getAllData())
  return user
})

export const addUser = createAsyncThunk('appUsuarios/addUser', async (user, { dispatch, getState }) => { 
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Usuarios/crear`, user)
  await dispatch(getData(getState().usuarios.params))
  await dispatch(getAllData())
  return response
})

export const deleteUser = createAsyncThunk('appUsuarios/deleteUser', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/Usuarios/${id}`)
  await dispatch(getData(getState().usuarios.params))
  await dispatch(getAllData())
  return response.data
})

export const downloadExcel = createAsyncThunk('appUsuarios/donwloadExcel', async params => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Usuarios/excel`, params)
  return response
})

export const appUsersSlice = createSlice({
  name: 'appUsuarios',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    regiones: [],
    roles: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.allData = action.payload.allData
        state.total = action.payload.totalPages
        state.params = action.payload.params
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getRegiones.fulfilled, (state, action) => {
        state.regiones = action.payload
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.roles = action.payload
      })
  }
})

export default appUsersSlice.reducer
