// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appComunas/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Comunas`)
  return response.data.allItems
})

export const getData = createAsyncThunk('appComunas/getData', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Comunas`, {params: param})
  return {
    param,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getComuna = createAsyncThunk('appComunas/getComuna', async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Comunas/${ id }`)
  return response.data.data
})

export const addComuna = createAsyncThunk('appComunas/addComuna', async (Comuna, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/comunas/crear`, Comuna)
  await dispatch(getData(getState().comunas.params))
  await dispatch(getAllData())
  return response.data
})

export const deleteComuna = createAsyncThunk('appComunas/deleteComuna', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/Comunas/${id}`)
  await dispatch(getData(getState().comunas.params))
  await dispatch(getAllData())
  return response.data
})

export const saveComuna = createAsyncThunk('appComunas/saveComuna', async (Comuna, { dispatch, getState }) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Comunas`, Comuna)
  await dispatch(getData(getState().comunas.params))
  await dispatch(getAllData())
  return response.data
})

export const getProvincias = createAsyncThunk('appComunas/getProvincias', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Provincias`)
  return response.data.allItems
})

export const downloadExcel = createAsyncThunk('appComunas/downloadExcel', async params => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/comunas/excel`, params)
  return response
})

export const appComunasSlice = createSlice({
  name: 'appComunas',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    provincias: [],
    selectedComuna: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getComuna.fulfilled, (state, action) => {
        state.selectedComuna = action.payload
      })
      .addCase(getProvincias.fulfilled, (state, action) => {
        state.provincias = action.payload
      })
  }
})

export default appComunasSlice.reducer
