// ** Reducers Imports
import navbar from "./navbar"
import layout from "./layout"
import auth from './authentication'

import acciones from '@src/views/apps/acciones/store'
import comunas from '@src/views/apps/comunas/store'
import provincias from '@src/views/apps/provincias/store'
import regiones from '@src/views/apps/regiones/store'
import usuarios from '@src/views/apps/usuarios/store'
import comunasEnergeticas from '@src/views/apps/comunas-energeticas/store'
import talleres from '@src/views/apps/talleres/store'
import informes from '@src/views/apps/informes/store'
import proyectos from '@src/views/apps/proyectos/store'

const rootReducer = { 
  auth,
  provincias,
  regiones,
  comunas,
  navbar,
  layout,
  usuarios,
  comunasEnergeticas,
  talleres,
  informes,
  proyectos,
  acciones
}

export default rootReducer
