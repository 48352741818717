// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appProyectos/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Proyectos`)
  return response.data.allItems
})

export const getData = createAsyncThunk('appProyectos/getData', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Proyectos`, {params:param})
  return {
    param,
    data: response.data.data,
    allData: response.data.allItems,
    totalPages: response.data.total
  }
})

export const addProyecto = createAsyncThunk('appProyectos/addProyecto', async (Proyecto, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Proyectos/crear`, Proyecto)
  await dispatch(getData(getState().proyectos.params))
  await dispatch(getAllData())
  return response.data
})

export const saveProyecto = createAsyncThunk('appProyectos/saveProyecto', async (Proyecto) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Proyectos`, Proyecto)
  return response.data
})

export const approveProyecto = createAsyncThunk('appProyectos/approveProyecto', async (Proyecto) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Proyectos/aprobar`, Proyecto)
  return response.data
})

export const rejectProyecto = createAsyncThunk('appProyectos/rejectProyecto', async (Proyecto) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Proyectos/rechazar`, Proyecto)
  return response.data
})

export const getProyectoById = createAsyncThunk('appProyectos/getProyectoById', async proyectoId => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Proyectos/${proyectoId}`)
  return response.data.data
})

export const getBitacoraProyecto = createAsyncThunk('appProyectos/getBitacoraProyecto', async proyectoId => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Proyectos/Bitacora/${proyectoId}`)
  return response.data.allItems
})

export const getDocumentosProyecto = createAsyncThunk('appProyectos/getDocumentosProyecto', async proyectoId => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Proyectos/Documentos/${proyectoId}`)
  return response.data.allItems
})

export const downloadExcel = createAsyncThunk('appProyectos/downloadExcel', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Proyectos/excel`, { params:param })
  return response
})

export const getComunasEnergeticas = createAsyncThunk('appProyectos/getComunasEnergeticas', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ComunasEnergeticas`, {params:param})
  return response.data.allItems
})

export const getAccionesComunaEnergetica = createAsyncThunk('appProyectos/getAccionesComunaEnergetica', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Acciones`, {params:param})
  return {
    param,
    data: response.data.data,
    allData: response.data.allItems,
    totalPages: response.data.total
  }
})

export const getEstadoEjecucion = createAsyncThunk('appProyectos/getEstadoEjecucion', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Proyectos/GetEstadosEjecucion`)
  return response.data.allItems
})

export const getPlazosProyecto = createAsyncThunk('appProyectos/getPlazosProyecto', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Proyectos/GetPlazosProyecto`)
  return response.data.allItems
})

export const getCategoriasBase = createAsyncThunk('appProyectos/getCategoriasBase', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/CategoriasBase`, {params:param})
  return response.data.allItems
})

export const getCriteriosEvaluacionBaseByCategoriaBase = createAsyncThunk('appProyectos/getCriteriosEvaluacionBase', async categoriaBaseId => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/CriteriosEvaluacionBase?CategoriaBaseId=${categoriaBaseId}`)
  return response.data.allItems//.filter(ces => ces.comunasEnergeticasCount <= 0)
})

export const getAccionesProyecto = createAsyncThunk('appProyectos/getAccionesProyecto', async proyectoId => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Proyectos/Acciones?ProyectoId=${proyectoId}`)
  return response.data.allItems//.filter(ces => ces.comunasEnergeticasCount <= 0)
})

export const addUsuarioEncargadoMunicipal = createAsyncThunk('appProyectos/addUsuarioEncargadoMunicipal', async user => { 
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Usuarios/crear`, user)
  return response
})

export const appProyectosSlice = createSlice({
  name: 'appProyectos',
  initialState: {
    proyectoSelected: {},
    data: [],
    total: 1,
    params: {},
    allData: [],
    bitacora: [],
    estadosEjecucion: [],
    plazosProyecto: [],
    comunasEnergeticas: [],
    categoriasBase: [],
    criteriosEvaluacionBase: [],
    accionesComunaEnergetica: [],
    accionesProyecto: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload.totalPages
      state.params = action.payload.param
      state.allData = action.payload.allData
    })
    .addCase(getAllData.fulfilled, (state, action) => {
      state.allData = action.payload
    })
    .addCase(getProyectoById.fulfilled, (state, action) => {
      state.proyectoSelected = action.payload
    })
     .addCase(getBitacoraProyecto.fulfilled, (state, action) => {
      state.bitacora = action.payload
    })
    .addCase(getEstadoEjecucion.fulfilled, (state, action) => {
      state.estadosEjecucion = action.payload
    })
    .addCase(getPlazosProyecto.fulfilled, (state, action) => {
      state.plazosProyecto = action.payload
    })
    .addCase(getComunasEnergeticas.fulfilled, (state, action) => {
      state.comunasEnergeticas = action.payload
    })
    .addCase(getAccionesComunaEnergetica.fulfilled, (state, action) => {
      state.accionesComunaEnergetica = action.payload.data
    })
    .addCase(getAccionesProyecto.fulfilled, (state, action) => {
      state.accionesProyecto = action.payload
    })
    .addCase(getCategoriasBase.fulfilled, (state, action) => {
      state.categoriasBase = action.payload
    })
    .addCase(getCriteriosEvaluacionBaseByCategoriaBase.fulfilled, (state, action) => {
      state.criteriosEvaluacionBase = action.payload
    })
  }
})


export default appProyectosSlice.reducer
