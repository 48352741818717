// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appAcciones/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Acciones`)
  return response.data.allItems
})

export const getData = createAsyncThunk('appAcciones/getData', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Acciones`, {params:param})
  return {
    param,
    data: response.data.data,
    allData: response.data.allItems,
    totalPages: response.data.total
  }
})

export const addAccion = createAsyncThunk('appAcciones/addAccion', async (Accion, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Acciones/crear`, Accion)
  await dispatch(getData(getState().acciones.params))
  await dispatch(getAllData())
  return response.data
})

export const saveAccion = createAsyncThunk('appAcciones/saveAccion', async (Accion, { dispatch, getState }) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Acciones`, Accion)
  await dispatch(getData(getState().acciones.params))
  await dispatch(getAllData())
  return response.data
})

export const downloadExcel = createAsyncThunk('appAcciones/downloadExcel', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Acciones/excel`, { params:param })
  return response
})

export const getComunasEnergeticas = createAsyncThunk('appAcciones/getComunasEnergeticas', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ComunasEnergeticas`, {params:param})
  return response.data.allItems
})

export const getAccionById = createAsyncThunk('appAcciones/getAccionById', async accionId => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Acciones/${accionId}`)
  return response.data.data
})

export const getCategoriasBase = createAsyncThunk('appAcciones/getCategoriasBase', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/CategoriasBase`, {params:param})
  return response.data.allItems
})

export const getCriteriosEvaluacionBaseByCategoriaBase = createAsyncThunk('appAcciones/getCriteriosEvaluacionBase', async categoriaBaseId => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/CriteriosEvaluacionBase?CategoriaBaseId=${categoriaBaseId}`)
  return response.data.allItems//.filter(ces => ces.comunasEnergeticasCount <= 0)
})

export const appAccionesSlice = createSlice({
  name: 'appAcciones',
  initialState: {
    accionSelected: {},
    data: [],
    total: 1,
    params: {},
    allData: [],
    comunasEnergeticas: [],
    categoriasBase: [],
    criteriosEvaluacionBase: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload.totalPages
      state.params = action.payload.param
      state.allData = action.payload.allData
    })
    .addCase(getAllData.fulfilled, (state, action) => {
      state.allData = action.payload
    })
    .addCase(getAccionById.fulfilled, (state, action) => {
      state.accionSelected = action.payload
    })
    .addCase(getComunasEnergeticas.fulfilled, (state, action) => {
      state.comunasEnergeticas = action.payload
    })
    .addCase(getCategoriasBase.fulfilled, (state, action) => {
      state.categoriasBase = action.payload
    })
    .addCase(getCriteriosEvaluacionBaseByCategoriaBase.fulfilled, (state, action) => {
      state.criteriosEvaluacionBase = action.payload
    })
  }
})


export default appAccionesSlice.reducer
