// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appComunasEnergeticas/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ComunasEnergeticas`)
  return response.data.allItems
})

export const getData = createAsyncThunk('appComunasEnergeticas/getData', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ComunasEnergeticas`, {params:param})
  return {
    param,
    data: response.data.data,
    allData: response.data.allItems,
    totalPages: response.data.total
  }
})

export const getDataAcreditacion = createAsyncThunk('appComunasEnergeticas/getDataAcreditacion', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ComunasEnergeticas/Acreditacion`, {params:param})
  return {
    param,
    data: response.data.data,
    allData: response.data.allItems,
    totalPages: response.data.total
  }
})

export const addComunaEnergetica = createAsyncThunk('appComunasEnergeticas/addComunaEnergetica', async (ComunaEnergetica, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/ComunasEnergeticas/crear`, ComunaEnergetica)
  await dispatch(getData(getState().comunasEnergeticas.params))
  await dispatch(getAllData())
  return response.data
})

export const saveComunaEnergetica = createAsyncThunk('appComunasEnergeticas/saveComunaEnergetica', async (ComunaEnergetica) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/ComunasEnergeticas`, ComunaEnergetica)
  return response.data
})

export const cerrarFaseEel = createAsyncThunk('appComunasEnergeticas/cerrarFaseEel', async (params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/ComunasEnergeticas/CerrarFaseEel`, params)
  return response.data
})

export const iniciarAcreditacion = createAsyncThunk('appComunasEnergeticas/iniciarAcreditacion', async (params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/ComunasEnergeticas/IniciarAcreditacion`, params)
  return response.data
})

export const approveComunaEnergetica = createAsyncThunk('appComunasEnergeticas/approveComunaEnergetica', async (ComunaEnergetica) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/ComunasEnergeticas/aprobar`, ComunaEnergetica)
  return response.data
})

export const rejectComunaEnergetica = createAsyncThunk('appComunasEnergeticas/rejectComunaEnergetica', async (ComunaEnergetica) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/ComunasEnergeticas/rechazar`, ComunaEnergetica)
  return response.data
})

export const getComunaEnergeticaById = createAsyncThunk('appComunasEnergeticas/getComunaEnergeticaById', async comunaEnergeticaId => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ComunasEnergeticas/${comunaEnergeticaId}`)
  return response.data.data
})

export const getBitacoraComunaEnergetica = createAsyncThunk('appComunasEnergeticas/getBitacoraComunaEnergetica', async comunaEnergeticaId => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ComunasEnergeticas/Bitacora/${comunaEnergeticaId}`)
  return response.data.allItems
})

export const getDocumentosComunaEnergetica = createAsyncThunk('appComunasEnergeticas/getDocumentosComunaEnergetica', async comunaEnergeticaId => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ComunasEnergeticas/Documentos/${comunaEnergeticaId}`)
  return response.data.allItems
})

export const downloadExcel = createAsyncThunk('appComunasEnergeticas/downloadExcel', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ComunasEnergeticas/excel`, { params:param })
  return response
})

export const getRegiones = createAsyncThunk('appComunasEnergeticas/getRegiones', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Regiones`, {params:param})
  return response.data.allItems
})

export const getComunas = createAsyncThunk('appComunasEnergeticas/getComunas', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Comunas`, {params:param})
  //En este caso filtrar las que ya tienen comuna energética asociada
  return response.data.allItems.filter(ces => ces.comunasEnergeticasCount <= 0)
})

export const getEstadosTotales = createAsyncThunk('appComunasEnergeticas/getEstadosTotales', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ComunasEnergeticas/totales`)
  return response.data.data
})

export const addUsuarioEncargadoMunicipal = createAsyncThunk('appComunasEnergeticas/addUsuarioEncargadoMunicipal', async user => { 
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Usuarios/crear`, user)
  return response
})

export const getUsuariosComuna = createAsyncThunk('appComunasEnergeticas/getUsuariosComuna', async (comunaId) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Usuarios?ComunaId=${comunaId}`)
  return response.data.data
})

export const getTalleres = createAsyncThunk('appComunasEnergeticas/getTalleres', async (ceId) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Talleres?ComunaEnergeticaId=${ceId}`)
  return response.data.data
})

export const getInformes = createAsyncThunk('appComunasEnergeticas/getInformes', async (ceId) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Informes?ComunaEnergeticaId=${ceId}`)
  return response.data.data
})

export const getProyectos = createAsyncThunk('appComunasEnergeticas/getProyectos', async (ceId) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Proyectos?ComunaEnergeticaId=${ceId}`)
  return response.data.data
})

export const getAcciones = createAsyncThunk('appComunasEnergeticas/getAcciones', async (ceId) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Acciones?ComunaEnergeticaId=${ceId}`)
  return response.data.data
})

export const getInstanciasCriterioEvaluacion = createAsyncThunk('appComunasEnergeticas/getInstanciasCriterioEvaluacion', async (ceId) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/InstanciasCriterioEvaluacion?ComunaEnergeticaId=${ceId}`)
  return response.data.data
})

export const appComunasEnergeticasSlice = createSlice({
  name: 'appComunasEnergeticas',
  initialState: {
    comunaEnergeticaSelected: {},
    data: [],
    total: 1,
    params: {},
    allData: [],
    //totales: {totalProcesos: 0, totalDocente: 0, totalRenta: 0, totalOperaciones: 0},
    //pendientes: [],
    bitacora: [],
    comunas: [],
    talleres: [],
    informes: [],
    //proyectos: [],
    acciones: [],
    usuariosComuna: [],
    instanciasCriterioEvaluacion: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload.totalPages
      state.params = action.payload.param
      state.allData = action.payload.allData
    })
    .addCase(getDataAcreditacion.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload.totalPages
      state.params = action.payload.param
      state.allData = action.payload.allData
    })
    .addCase(getAllData.fulfilled, (state, action) => {
      state.allData = action.payload
    })
    .addCase(getComunaEnergeticaById.fulfilled, (state, action) => {
      state.comunaEnergeticaSelected = action.payload
    })
    .addCase(getBitacoraComunaEnergetica.fulfilled, (state, action) => {
      state.bitacora = action.payload
    })
    .addCase(getTalleres.fulfilled, (state, action) => {
      state.talleres = action.payload
    })
    .addCase(getInformes.fulfilled, (state, action) => {
      state.informes = action.payload
    })
    .addCase(getAcciones.fulfilled, (state, action) => {
      state.acciones = action.payload
    })
    .addCase(getInstanciasCriterioEvaluacion.fulfilled, (state, action) => {
      state.instanciasCriterioEvaluacion = action.payload
    })
    .addCase(getUsuariosComuna.fulfilled, (state, action) => {
      state.usuariosComuna = action.payload
    })
    .addCase(getComunas.fulfilled, (state, action) => {
      state.comunas = action.payload
    })
    // .addCase(getEstadosTotales.fulfilled, (state, action) => {
    //     state.totales.totalProcesos = action.payload.totalProcesos
    //     state.totales.totalDocente = action.payload.totalCarreraDocente
    //     state.totales.totalRenta = action.payload.totalHomologacionRenta
    //     state.totales.totalOperaciones = action.payload.totalOperaciones
    // })
  }
})


export default appComunasEnergeticasSlice.reducer
