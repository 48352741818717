// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appTalleres/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Talleres`)
  return response.data.allItems
})

export const getData = createAsyncThunk('appTalleres/getData', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Talleres`, {params:param})
  return {
    param,
    data: response.data.data,
    allData: response.data.allItems,
    totalPages: response.data.total
  }
})

export const addTaller = createAsyncThunk('appTalleres/addTaller', async (Taller, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Talleres/crear`, Taller)
  await dispatch(getData(getState().talleres.params))
  await dispatch(getAllData())
  return response.data
})

export const saveTaller = createAsyncThunk('appTalleres/saveTaller', async (Taller) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Talleres`, Taller)
  return response.data
})

export const approveTaller = createAsyncThunk('appTalleres/approveTaller', async (Taller) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Talleres/aprobar`, Taller)
  return response.data
})

export const rejectTaller = createAsyncThunk('appTalleres/rejectTaller', async (Taller) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Talleres/rechazar`, Taller)
  return response.data
})

export const downloadExcel = createAsyncThunk('appTalleres/downloadExcel', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Talleres/excel`, { params:param })
  return response
})

export const getRegiones = createAsyncThunk('appTalleres/getRegiones', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Regiones`, {params:param})
  return response.data.allItems
})

export const getTallerById = createAsyncThunk('appTalleres/getTallerById', async tallerId => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Talleres/${tallerId}`)
  return response.data.data
})

export const getBitacoraTaller = createAsyncThunk('appTalleres/getBitacoraTaller', async tallerId => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Talleres/Bitacora/${tallerId}`)
  return response.data.allItems
})

export const getDocumentosTaller = createAsyncThunk('appTalleres/getDocumentosTaller', async tallerId => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Talleres/Documentos/${tallerId}`)
  return response.data.allItems
})

export const getAsistentesTaller = createAsyncThunk('appTalleres/getAsistentesTaller', async tallerId => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Talleres/Asistentes/${tallerId}`)
  return response.data.allItems
})

export const addAsistenteTaller = createAsyncThunk('appTalleres/addAsistenteTaller', async (AsistenteTaller) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Talleres/Asistentes/crear`, AsistenteTaller)
  return response.data
})

export const deleteAsistenteTaller = createAsyncThunk('appTalleres/deleteAsistenteTaller', async (asistenteTallerId) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/Talleres/Asistentes/${asistenteTallerId}`)
  return response.data
})

export const getComunasEnergeticas = createAsyncThunk('appTalleres/getComunasEnergeticas', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ComunasEnergeticas`, {params:param})
  return response.data.allItems
})

export const appTalleresSlice = createSlice({
  name: 'appTalleres',
  initialState: {
    tallerSelected: {},
    data: [],
    total: 1,
    params: {},
    allData: [],
    //totales: {totalProcesos: 0, totalDocente: 0, totalRenta: 0, totalOperaciones: 0},
    comunasEnergeticas: [],
    bitacora: [],
    documentos: [],
    asistentes: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload.totalPages
      state.params = action.payload.param
      state.allData = action.payload.allData
    })
    .addCase(getAllData.fulfilled, (state, action) => {
      state.allData = action.payload
    })
    .addCase(getTallerById.fulfilled, (state, action) => {
      state.tallerSelected = action.payload
    })
    .addCase(getComunasEnergeticas.fulfilled, (state, action) => {
      state.comunasEnergeticas = action.payload
    })
    .addCase(getDocumentosTaller.fulfilled, (state, action) => {
      state.documentos = action.payload
    })
    .addCase(getBitacoraTaller.fulfilled, (state, action) => {
      state.bitacora = action.payload
    })
    .addCase(getAsistentesTaller.fulfilled, (state, action) => {
      state.asistentes = action.payload
    })
  }
})


export default appTalleresSlice.reducer
