// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

axios.baseURL = process.env.REACT_APP_API_URL

export const getAllData = createAsyncThunk('appRegiones/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Regiones`)
  return response.data.allItems
})

export const getData = createAsyncThunk('appRegiones/getData', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Regiones`, {params: param})
  return {
    param,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getRegion = createAsyncThunk('appRegiones/getRegion', async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Regiones/${id}`)
  return response.data.data
})

export const addRegion = createAsyncThunk('appRegiones/addRegion', async (Region, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Regiones/crear`, Region)
  await dispatch(getData(getState().regiones.params))
  await dispatch(getAllData())
  return response.data
})

export const saveRegion = createAsyncThunk('appRegiones/saveRegion', async (Region, { dispatch, getState }) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Regiones`, Region)
  await dispatch(getData(getState().regiones.params))
  await dispatch(getAllData())
  return response.data
})

export const deleteRegion = createAsyncThunk('appRegiones/deleteRegion', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/Regiones/${id}`)
  await dispatch(getData(getState().regiones.params))
  await dispatch(getAllData())
  return response.data
})

export const downloadExcel = createAsyncThunk('appRegiones/downloadExcel', async params => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/regiones/excel`, params)
  return response
})

export const appRegionesSlice = createSlice({
  name: 'appRegiones',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedRegion: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getRegion.fulfilled, (state, action) => {
        state.selectedRegion = action.payload
      })
  }
})

export default appRegionesSlice.reducer
